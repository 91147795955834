import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LocationWhereInput,
  Segment,
  SegmentType,
  UserLocationInput,
} from "../../../gql/hooks";
import { ProductDataDetails } from "../../../pages/PosList/partials/posfilters/partials/MissingProductsPane/utils/types";
import {
  ClientTypeType,
  LastVisitOptions,
  SetBrandIdsParams,
  SetClientTypeParams,
  SetIsOwnerParams,
  SetLaLigaParams,
  SetLastVisitDateParams,
  SetMapLocationParams,
  SetMissingProductsParams,
  SetQueryVariablesParams,
  SetRentabilibarParams,
  SetSearchParams,
  SetSegmentsParams,
  SetSegmentTypesParams,
  SetUnderContractParams,
  SetUserLocationParams,
  SortType,
} from "./types";

const LIMIT = 100;

interface PosFiltersState {
  filters: {
    search: string;
    isOwner?: boolean;
    underContract?: boolean;
    brandIds: number[];
    laLiga?: boolean;
    lastVisitDate?: LastVisitOptions;
    missingProducts: ProductDataDetails[];
    rentabilibar?: boolean;
    segments: Segment[];
    segmentTypes: SegmentType[];
    clientType?: ClientTypeType[];
  };
  sort: SortType;
  mapLocation?: LocationWhereInput;
  userLocation?: UserLocationInput;
  filterByMapLocation?: boolean;
  queryVariables: any;
}

export const initialState: PosFiltersState = {
  filters: {
    search: "",
    isOwner: undefined,
    underContract: undefined,
    brandIds: [],
    laLiga: undefined,
    lastVisitDate: undefined,
    missingProducts: [],
    rentabilibar: undefined,
    segments: [],
    segmentTypes: [],
  },
  sort: null,
  userLocation: undefined,
  queryVariables: {
    take: LIMIT,
    skip: 0,
    isOwner: undefined,
    userLocation: undefined,
  },
};

const slice = createSlice({
  name: "posFilters",
  initialState,
  reducers: {
    SetSearch(state, { payload: { search } }: PayloadAction<SetSearchParams>) {
      state.filters.search = search;
    },
    SetIsOwner(
      state,
      { payload: { isOwner } }: PayloadAction<SetIsOwnerParams>,
    ) {
      state.filters.isOwner = isOwner;
    },
    SetUserLocation(
      state,
      {
        payload: { userLocation, sortBy },
      }: PayloadAction<SetUserLocationParams>,
    ) {
      state.userLocation = userLocation;
      state.sort = sortBy;
    },
    SetMapLocation(
      state,
      { payload: { location } }: PayloadAction<SetMapLocationParams>,
    ) {
      state.mapLocation = location;
    },
    SetLastVisitDate(
      state,
      { payload: { lastVisitDate } }: PayloadAction<SetLastVisitDateParams>,
    ) {
      state.filters.lastVisitDate = lastVisitDate;
    },
    setFilterByMapLocation(
      state,
      {
        payload: { filterByMapLocation },
      }: PayloadAction<{ filterByMapLocation: boolean }>,
    ) {
      state.filterByMapLocation = filterByMapLocation;
    },
    SetBrandIds(
      state,
      { payload: { brandIds } }: PayloadAction<SetBrandIdsParams>,
    ) {
      state.filters.brandIds = brandIds;
    },
    SetSegmentTypes(
      state,
      { payload: { segmentTypes } }: PayloadAction<SetSegmentTypesParams>,
    ) {
      state.filters.segmentTypes = segmentTypes;
    },
    SetSegments(
      state,
      { payload: { segments } }: PayloadAction<SetSegmentsParams>,
    ) {
      state.filters.segments = segments;
    },
    SetClientType(
      state,
      { payload: { clientType } }: PayloadAction<SetClientTypeParams>,
    ) {
      state.filters.clientType = clientType;
    },
    SetUnderContract(
      state,
      { payload: { underContract } }: PayloadAction<SetUnderContractParams>,
    ) {
      state.filters.underContract = underContract;
    },
    SetLaLiga(state, { payload: { laLiga } }: PayloadAction<SetLaLigaParams>) {
      state.filters.laLiga = laLiga;
    },
    SetRentabilibar(
      state,
      { payload: { rentabilibar } }: PayloadAction<SetRentabilibarParams>,
    ) {
      state.filters.rentabilibar = rentabilibar;
    },
    SetMissingProducts(
      state,
      { payload: { missingProducts } }: PayloadAction<SetMissingProductsParams>,
    ) {
      state.filters.missingProducts = missingProducts;
    },
    SetQueryVariables(
      state,
      { payload: { variables } }: PayloadAction<SetQueryVariablesParams>,
    ) {
      state.queryVariables = variables;
    },
    ResetPosFiltersState(
      state,
      { payload: { isOwner } }: PayloadAction<SetIsOwnerParams>,
    ) {
      const updatedInitialState = {
        ...initialState,
        mapLocation: state.mapLocation,
        filters: { ...initialState.filters, isOwner },
      };
      return updatedInitialState;
    },
  },
});

export const {
  SetSearch,
  SetIsOwner,
  SetBrandIds,
  SetLaLiga,
  SetRentabilibar,
  SetSegmentTypes,
  SetSegments,
  SetClientType,
  SetUnderContract,
  SetMissingProducts,
  SetLastVisitDate,
  SetQueryVariables,
  SetUserLocation,
  ResetPosFiltersState,
  SetMapLocation,
  setFilterByMapLocation,
} = slice.actions;
export default slice.reducer;
