import { IonContent, IonPage } from "@ionic/react";
import "./ProposalExternal.scss";
import { useEffect, useMemo, useState } from "react";
import { handleProposalExternal, Status } from "./ProposalExternalHandler";
import locales from "../../locales";
import { COOKIE_LANG } from "@france/superelements/utils";
import { getCookie } from "../../utils/security/cookies";
import LoadingPage from "../LoadingPage/LoadingPage";

interface ProposalExternalProps {}

const ProposalExternal: React.FC<ProposalExternalProps> = () => {
  const [currentLang, _setCurrentLang] = useState(
    getCookie(COOKIE_LANG) || "es",
  );
  const [status, setStatus] = useState(Status.LOADING);

  const t = useMemo(() => {
    return (value: string) =>
      (locales as any)?.[currentLang as string]?.[value];
  }, [currentLang]);

  useEffect(() => {
    const fetchData = async () => {
      const status = await handleProposalExternal();
      setStatus(status);
    };
    fetchData();
  }, []);

  if (!t) return <LoadingPage />;

  return (
    <IonPage id="ProposalExternal">
      <IonContent>
        <div className="text">{t(status)}</div>
      </IonContent>
    </IonPage>
  );
};

export default ProposalExternal;
