declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    env: {
      [key: string]: string;
    };
  }
}

const pEnv = window.env || process.env || {};

export const env = {
  GOOGLE_API_KEY: pEnv.REACT_APP_MAP_KEY || "",
  GRAPHQL_URL: pEnv.REACT_APP_API_URL + "/graphql",
  REST_URL: pEnv.REACT_APP_API_URL + "/msm",
  AUTH_URL: pEnv.REACT_APP_API_URL + "/oauth",
  ENV: pEnv.REACT_APP_ENV,
  VERSION: pEnv.REACT_APP_VERSION,
  ORIGIN: pEnv.REACT_APP_ORIGIN,
  AUTH0_DOMAIN: pEnv.REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: pEnv.REACT_APP_AUTH0_CLIENT_ID,
  AUTH0_CONNECTION: pEnv.REACT_APP_AUTH0_CONNECTION,
  OKTA_DOMAIN: pEnv.REACT_APP_OKTA_DOMAIN,
  OKTA_CLIENT_ID: pEnv.REACT_APP_OKTA_CLIENT_ID,
  AZURE_APP_INSIGHT: pEnv.REACT_APP_AZURE_APP_INSIGHT,
  SENTRY_URL: pEnv.REACT_APP_SENTRY_URL,
};
