import { downloadFile } from "@france/superelements/utils";

export const downloadDesktopFile = (
  url: string,
  name: string,
  type: string = "application/pdf",
) => {
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": type,
    },
  })
    .then((response) => response.blob())
    .then((blob) => downloadFile({ data: blob }, name));
};
