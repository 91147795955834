const getExtension = (type: string) => {
  switch (type) {
    case "application/pdf":
      return ".pdf";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return ".pptx";
    default:
      return ".pdf";
  }
};

export default getExtension;
