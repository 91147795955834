import { env } from "../../../env";
import AuthDevProvider from "../AuthDevProvider/AuthDevProvider";
import AuthProvider from "../AuthProvider/AuthProvider";

const AuthChecker: React.FC<{ children: any }> = ({ children }) => {
  if (env.AUTH0_DOMAIN) {
    return <AuthDevProvider>{children}</AuthDevProvider>;
  }
  return <AuthProvider>{children}</AuthProvider>;
};

export default AuthChecker;
