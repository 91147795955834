import { BrowserOptions, BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import packageInfo from "../../../package.json";
import { env } from "../../env";

type BeforeBreadcrumbCallback = NonNullable<BrowserOptions["beforeBreadcrumb"]>;

export const excludeGraphQLFetch: BeforeBreadcrumbCallback = (breadcrumb) => {
  if (breadcrumb.category === "fetch") {
    const url: string = breadcrumb.data?.url ?? "";

    if (url.includes("/graphql")) {
      return null;
    }
  }

  return breadcrumb;
};

export const initSentry = () => {
  if (env?.SENTRY_URL) {
    if (env?.ENV) {
      Sentry.init({
        dsn: env?.SENTRY_URL,
        integrations: [new BrowserTracing({ traceFetch: false })], // false to exclude redundant fetch breadcrumbs

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        normalizeDepth: 10,
        release: `mahou.sanmiguel.v${packageInfo.version}`,
        environment: env?.ENV,
        beforeBreadcrumb: excludeGraphQLFetch,
      });
    } else {
      console.error("Missing REACT_APP_ENV");
    }
  }
};
