import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { IonContent, IonModal, IonPage } from "@ionic/react";
import LoadingPage from "../../../pages/LoadingPage/LoadingPage";
import { Button, ButtonHighlight, Icon } from "@france/superelements/lib";
import SimpleMahouLoader from "../../../components/SimpleMahouLoader/SimpleMahouLoader";

import "./AuthDevProvider.scss";
import jwtDecode from "jwt-decode";
import locales from "../../../locales";
import ROUTES from "../../constants/routes";
import Auth0Request from "../../../utils/security/Auth0";
import { COOKIE_LANG } from "@france/superelements/utils";
import { getCookie } from "../../../utils/security/cookies";
import { trackUserLogged } from "../../../services/applicationInsight";
import ProposalExternal from "../../../pages/ProposalExternal/ProposalExternal";

let itDidRun = false;

enum PwdTypes {
  pwd = "password",
  txt = "text",
}

const AuthDevProvider: React.FC<any> = ({ children }) => {
  const refreshTokenRef = useRef<NodeJS.Timeout | null>(null);

  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [currentLang, setCurrentLang] = useState(
    getCookie(COOKIE_LANG) || "es",
  );
  const [loading, setLoading] = useState(false);
  const [pwdType, setPwdType] = useState(PwdTypes.pwd);
  const [appStarted, setAppStarted] = useState(false);

  // Memos
  const t = useMemo(() => {
    return (value: string) =>
      (locales as any)?.[currentLang as string]?.[value];
  }, [currentLang]);

  const langValues = useMemo(
    () =>
      ["es", "en"].map((value: string) => ({
        text: t(`lang_${value}`),
        value,
      })),
    [t],
  );

  const Auth0 = useMemo(() => new Auth0Request(), []);

  // Handlers
  const handleEmailChange = useCallback(
    (ev: any) => setEmail(ev.target.value),
    [],
  );

  const handlePwdChange = useCallback(
    (ev: any) => setPassword(ev.target.value),
    [],
  );

  const handlePwdType = useCallback(
    () =>
      setPwdType((prev) =>
        prev === PwdTypes.pwd ? PwdTypes.txt : PwdTypes.pwd,
      ),
    [],
  );

  const handleSubmit = useCallback(async () => {
    setError("");
    setLoading(true);
    const rsp: any = await Auth0.login(email, password);
    setLoading(false);
    if (rsp?.response?.data) {
      if (rsp?.response?.data.error) {
        setError(
          t(rsp.response.data.error) ||
            rsp.response.data.error_description ||
            "",
        );
      }
    }
  }, [Auth0, email, password, t]);

  const handleLangChange = useCallback(
    (value: string = "es") => {
      setCurrentLang(value);
    },
    [setCurrentLang],
  );

  // Inits
  const initApp = useCallback(async () => {
    const token = await Auth0.getAccessToken();

    // Clear Times
    if (refreshTokenRef.current) clearInterval(refreshTokenRef.current);

    if (window.location.href.includes(ROUTES.PROPOSAL_EXTERNAL)) return;
    // Check Validity of token
    if (window.location.pathname.includes(ROUTES.LOGOUT)) {
      await Auth0.logout();
    } else if (!!token && (await Auth0.checkExpiration())) {
      const finalToken = Auth0.getAccessToken();
      if (finalToken) {
        const payload = jwtDecode(finalToken.toString()) as any;
        if (payload?.sub) trackUserLogged(payload?.sub);
      }

      setAppStarted(true);
      refreshTokenRef.current = setInterval(
        async () => await Auth0.checkExpiration(),
        6000, // Every minute
      );
    }
  }, [Auth0]);

  useEffect(() => {
    if (!itDidRun) initApp();
    itDidRun = true;
  }, [initApp]);

  if (window.location.href.includes(ROUTES.PROPOSAL_EXTERNAL)) {
    return <ProposalExternal />;
  }

  if (!t) return <LoadingPage />;

  if (!appStarted)
    return (
      <IonPage id="AuthDevProvider">
        <IonModal isOpen={loading} className="FullScreenModal">
          <SimpleMahouLoader />
        </IonModal>
        <IonContent scrollY={false}>
          <div className="container">
            <div className="langButton px-ms mb-s">
              <Button
                size="small"
                text={t(`lang_${currentLang}`)}
                dropdownValues={langValues}
                onClick={handleLangChange}
              />
            </div>
            <div className="box">
              <div className="header">
                <img
                  src="https://ok9static.oktacdn.com/fs/bco/1/fs080lkzu2peYmuKQ417"
                  alt="Auth0"
                />
              </div>
              <div className="form">
                <div className="label">{t("sign_in")}</div>
                {!!error?.length && <div className="label error">{error}</div>}
                <div className="inputBox">
                  <div className="label">{t("email")}</div>
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    className={`${error ? "error" : ""}`}
                  />
                </div>
                <div className="inputBox">
                  <div className="label">{t("password")}</div>
                  <input
                    type={pwdType}
                    value={password}
                    onChange={handlePwdChange}
                    className={`${error ? "error" : ""} hasEye`}
                  />
                  <Icon
                    className="eye"
                    icon={pwdType === PwdTypes.pwd ? "eye" : "eye-off"}
                    onClick={handlePwdType}
                  />
                </div>
                <ButtonHighlight name={t("login")} onClick={handleSubmit} />
                {/* <div className="link">{t("forgot_password")}</div> */}
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  return <>{children}</>;
};

export default AuthDevProvider;
