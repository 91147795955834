import { PayloadAction } from "@reduxjs/toolkit";
import { ProposalState } from "..";
import { PackagedBeerDetailsContainer } from "../../../../gql/hooks";

type SetProductContainerParams = {
  formId: string;
  value: PackagedBeerDetailsContainer;
  isTank?: boolean;
};

function SetProductContainerReducer(
  state: ProposalState,
  {
    payload: { formId, value, isTank },
  }: PayloadAction<SetProductContainerParams>,
) {
  if (isTank) {
    state.productByFormId[formId].commercialConditions.isTank = true;
  } else {
    state.productByFormId[formId].isPending = false;
    state.productByFormId[formId].commercialConditions.container = value;
    state.productByFormId[formId].commercialConditions.isTank = false;

    // Reset the chosen packagedProductId
    state.productByFormId[formId].commercialConditions.packagedProductId =
      undefined;
  }
}

export default SetProductContainerReducer;
