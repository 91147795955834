import {
  CookiePrePath,
  deleteAllCookies,
  deleteCookie,
  getCookie,
  setCookie,
} from "./cookies";

import { TOKEN_KEY } from "@france/superelements/utils";

import axios from "axios";
import ROUTES from "../../router/constants/routes";
import { env } from "../../env";

const minutesDiff = (firstDate: Date, secondDate: Date) => {
  let differenceValue = (secondDate.getTime() - firstDate.getTime()) / 1000;
  differenceValue /= 60;
  return differenceValue;
};

const TOKEN_ACCESS = TOKEN_KEY;
const TOKEN_ID = `id_${TOKEN_KEY}`;
const TOKEN_EXP = `exp_${TOKEN_KEY}`;
const TOKEN_REFRESH = `refresh_${TOKEN_KEY}`;

class Auth0Request {
  // Request Information
  private scope: string = "openid profile email offline_access";

  private domain: string | undefined = env.AUTH0_DOMAIN;
  private clientId: string | undefined = env.AUTH0_CLIENT_ID;
  private connection: string = env.AUTH0_CONNECTION || "msm-dev";

  // Token Information
  private token = getCookie(TOKEN_ACCESS); // Access Token
  private idToken = getCookie(TOKEN_ID); // Id Token

  private exp = getCookie(TOKEN_EXP);
  private refreshToken = getCookie(TOKEN_REFRESH);

  // Getters
  public getAccessToken() {
    return this.token;
  }

  // Auth Methods
  public async login(email: string, password: string) {
    try {
      const options = {
        method: "POST",
        url: `https://${this.domain}/oauth/token`,
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: new URLSearchParams({
          grant_type: "password",
          username: email,
          password,
          client_id: this.clientId || "",
          audience: `https://${this.domain}/api/v2/`,
          scope: this.scope,
          connection: this.connection,
        }),
      };

      const resp = await axios.request(options);
      this.setAuth(resp.data);

      const prePath = getCookie(CookiePrePath);
      const nextLocation =
        !!prePath && typeof prePath === "string" ? prePath : ROUTES.HOME;
      deleteCookie(CookiePrePath);

      if (nextLocation === ROUTES.HOME) window.location.reload();
      else window.location.href = nextLocation;
      return resp;
    } catch (err) {
      return err;
    }
  }

  public async checkExpiration() {
    if (this.exp) {
      const diff = minutesDiff(new Date(), new Date(Number(this.exp) * 1000));
      if (diff < 2) {
        this.logout();
        return false;
      }
      return true;
    }
    this.logout();
    return false;
  }

  public async logout() {
    this.clearAuth();
    window.location.href = ROUTES.HOME;
  }

  public setAuth(params: {
    access_token: string;
    id_token: string;
    expires_in: number;
    refresh_token: string;
  }) {
    // Access Token
    this.token = params.access_token;
    setCookie(TOKEN_ACCESS, params.access_token);
    // Id Token
    this.idToken = params.id_token;
    setCookie(TOKEN_ID, params.id_token);
    // Exp
    this.exp = (params.expires_in + Date.now() / 1000).toString();
    setCookie(TOKEN_EXP, this.exp?.toString());
    // Refresh
    this.refreshToken = params.refresh_token;
    setCookie(TOKEN_REFRESH, params.refresh_token);
  }

  public clearAuth() {
    deleteAllCookies();
    localStorage.clear();
    this.token = null;
    this.idToken = null;
    this.refreshToken = null;
    this.exp = null;
  }
}

export default Auth0Request;
