import {
  Filesystem,
  Directory,
  DownloadFileOptions,
} from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import { isAndroid } from "react-device-detect";
import getExtension from "./library/getExtension";

export enum PermissionStatus {
  PROMPT = "prompt",
  PROMPT_WITH_RATIONALE = "prompt-with-rationale",
  GRANTED = "granted",
  DENIED = "denied",
}

export enum DownloadStatus {
  ERROR,
  PERMISSION_DENIED,
  SUCCESS,
}

export const getAndroidPermissions = async () => {
  try {
    const permissionStatus = await Filesystem.checkPermissions();
    if (permissionStatus.publicStorage !== PermissionStatus.GRANTED) {
      const requestPermissionResult = await Filesystem.requestPermissions();
      return requestPermissionResult.publicStorage;
    }

    return permissionStatus.publicStorage;
  } catch (e: unknown) {
    console.error(e);
  }
};

let pdfOpenLast: string | null = null;
export const openLastDownloaded = async () => {
  if (pdfOpenLast) {
    FileOpener.open({ filePath: pdfOpenLast });
  }
};

export const downloadMobileFile = async (
  url: string,
  name: string,
  id?: number,
  type: string = "application/pdf",
  open: boolean = true,
) => {
  const fileName = `${name.replace(
    getExtension(type),
    id ? `(${id})` : "",
  )}${getExtension(type)}`;
  const options: DownloadFileOptions = {
    url: url,
    directory: Directory.Documents,
    path: fileName,
  };
  let status = undefined;
  let granted = true;

  if (isAndroid) {
    try {
      const permissionStatus = await getAndroidPermissions();
      if (permissionStatus !== PermissionStatus.GRANTED) {
        status = DownloadStatus.PERMISSION_DENIED;
        granted = false;
      }
    } catch (e: unknown) {
      granted = false;
      status = DownloadStatus.PERMISSION_DENIED;
      console.error(e);
    }
  }
  if (granted) {
    try {
      const downloadResult = await Filesystem.downloadFile(options);
      if (downloadResult?.path) {
        pdfOpenLast = downloadResult.path;
        if (open) FileOpener.open({ filePath: downloadResult.path });
        status = DownloadStatus.SUCCESS;
      }
    } catch (e: unknown) {
      console.error(e);
      status = DownloadStatus.ERROR;
    }
  }
  return status;
};
