import { IconType } from "@france/superelements/lib";
import { ProposalStates } from "../../gql/hooks";

// Enum
export enum FileMimeType {
  PDF = "application/pdf",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
}

export enum categories {
  COMMERCIAL_CONDITIONS = "conditions",
  ASSORTMENT = "assortment",
}

// Type
export type ConditionType = {
  id: number;
  product: string;
  type: "Barri" | "Caja";
  rate: string;
  unitAmount: number;
  discountPerUnit: string;
  finalPricePerUnit: string;
  finalPricePerLiter: string;
  amortBonus: string;
};

export type ProposalStatusType = {
  value: ProposalStates;
  icon: IconType;
  name: string;
};

export const CONDITIONS_MOCK: ConditionType[] = [
  {
    id: 1,
    product: "Mahou Clasica-30L (#626)",
    type: "Barri",
    rate: "74,97€",
    unitAmount: 10,
    discountPerUnit: "14,00€/uc",
    finalPricePerUnit: "60,97€",
    finalPricePerLiter: "2,03/L€",
    amortBonus: "0,5€ Rappel/L",
  },
  {
    id: 2,
    product: "Mahou Clasica-30L (#626)",
    type: "Barri",
    rate: "74,97€",
    unitAmount: 10,
    discountPerUnit: "14,00€/uc",
    finalPricePerUnit: "60,97€",
    finalPricePerLiter: "2,03/L€",
    amortBonus: "0,5€ Rappel/L",
  },
  {
    id: 3,
    product: "Mahou Clasica-30L (#626)",
    type: "Barri",
    rate: "74,97€",
    unitAmount: 10,
    discountPerUnit: "14,00€/uc",
    finalPricePerUnit: "60,97€",
    finalPricePerLiter: "2,03/L€",
    amortBonus: "0,5€ Rappel/L",
  },
];
